import {
  Badge,
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Typography,
} from "@mui/material";
import Link from "next/link";
import React from "react";
import { flexBetween, flexCenter } from "../../../theme/commonThemes";
import { useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { clearAuth, getAuth } from "../../api/auth";
import { actions } from "../../modules/auth";
import { Check, Comment, NotificationsNoneOutlined } from "@mui/icons-material";
import { useQuery } from "react-query";
import notificationModule from "../../modules/notificationModule";
const useStyles = makeStyles((theme) => ({
  menuRoot: {
    "& .MuiList-root": {
      padding: 0,
    },
    "& .MuiMenuItem-root": {
      borderRadius: "8px",
    },
  },
}));
const ProfileSettings = ({ notificationData }) => {
  const { user_profile } = getAuth();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const fetchInfiniteNotifications = async () => {
    const res = await dispatch(
      notificationModule.getAll({
        limit: 20,
        page: 1,
      })
    );
    return res.data;
  };
  const { data = null } = user_profile?.id ? useQuery(
    ["notificationCountDeskLists", user_profile?.id],
    () => fetchInfiniteNotifications()
  ) : {};

  let notificationCount = 0;
  if (data && data.data) {
    notificationCount = data?.data.filter((x, i) => {
      return x.new === true;
    }).length;
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = (e) => {
    e.preventDefault();
    clearAuth();
    dispatch(actions.logout());
  };

  const AuthenticatedLists = [
    {
      name: "Messages",
      href: "/chat/*",
    },
    {
      name: "Notifications",
      href: "/notifications",
      newNotification: notificationCount && notificationCount,
    },
    {
      name: "My Booking",
      href: "/booking",
      hasDivider: true,
    },

    {
      name: "About Travela",
      href: "https://about.travela.xyz/",
      outerLink: true,
    },
    {
      name: "Account",
      href: "/profile/edit",
      hasDivider: true,
    },

    {
      name: "Log out",
      href: "#",
      onClick: true,
    },
  ];

  return (
    <Box sx={flexCenter}>
      <Link href="#" shallow>
        <Typography variant="subtitle1"> Earn by Host</Typography>
      </Link>
      <Stack>
        <Button
          sx={{
            height: "40px",
            width: "72px",
            border: "1px solid var(--border-color) !important",
            borderRadius: "63px",
            backgroundColor: "#ffffff !important",
          }}
          variant="outlined"
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <Box sx={{ mr: 1, mt: 1 }}>
            <img src="/deskImages/menu.svg" />
          </Box>
          <img src="/deskImages/user.svg" />
        </Button>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          className={classes.menuRoot}
          PaperProps={{
            style: {
              marginTop: "8px",
              borderRadius: "8px",
              boxShadow: "0px 6px 72px rgba(63, 0, 30, 0.16)",
              textAlign: "end",
              padding: "12px",
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {user_profile ? (
            <MenuList>
              {AuthenticatedLists.map((authenticatedList, index) => {
                return (
                  <Box key={index}>
                    <Link
                      href={authenticatedList.href && authenticatedList.href}
                      as={authenticatedList.href && authenticatedList.href}
                      passHref
                      target={authenticatedList?.outerLink && "_blank"}
                      shallow
                    >
                      <MenuItem
                        onClick={authenticatedList.onClick && handleLogOut}
                      >
                        <ListItemText
                          primaryTypographyProps={{
                            fontSize: 14,
                            color: "var(--text-secondary)",
                            fontWeight: 500,
                          }}
                          primary={
                            <Box sx={{ ...flexBetween }}>
                              <Box>{authenticatedList.name}</Box>
                            </Box>
                          }
                        />

                        {authenticatedList.newNotification &&
                        authenticatedList.newNotification > 0 ? (
                          <Badge
                            sx={{ ml: 4 }}
                            color="primary"
                            badgeContent={authenticatedList.newNotification}
                            max={99}
                          ></Badge>
                        ) : null}
                      </MenuItem>
                    </Link>
                    {authenticatedList.hasDivider && <Divider sx={{ my: 1 }} />}
                  </Box>
                );
              })}
            </MenuList>
          ) : (
            <MenuList>
              <Link href="/signin" as="/signin" passHref shallow>
                <MenuItem>
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: 14,
                      color: "var(--text-secondary)",
                      fontWeight: 500,
                    }}
                    primary="Log In"
                  />
                </MenuItem>
              </Link>

              <Link href="/signup" as="/signup" passHref shallow>
                <MenuItem>
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: 14,
                      color: "var(--text-secondary)",
                      fontWeight: 500,
                    }}
                    primary="Sign Up"
                  />
                </MenuItem>
              </Link>
              <Divider sx={{ my: 1 }} />
              <MenuItem>
                <ListItemText
                  primaryTypographyProps={{
                    fontSize: 14,
                    color: "var(--text-secondary)",
                    fontWeight: 500,
                  }}
                  primary="Travela your home"
                />
              </MenuItem>
              <MenuItem>
                <ListItemText
                  primaryTypographyProps={{
                    fontSize: 14,
                    color: "var(--text-secondary)",
                    fontWeight: 500,
                  }}
                  primary="Help"
                />
              </MenuItem>
            </MenuList>
          )}
        </Menu>
      </Stack>
    </Box>
  );
};
export default ProfileSettings;
