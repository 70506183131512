import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Link from "next/link";
import React from "react";
import { dFlex, flexBetween, flexCenter } from "../../../theme/commonThemes";
import Logo from "../Logo";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "80px",
    "& .MuiInputBase-input": {
      "&::placeholder": {
        opacity: 1,
      },
    },
  },
}));
export default function Footer() {
  const classes = useStyles();
  const footerList = [
    {
      name: "About",
      url: "/about",
    },
    // {
    //   name: "Stays",
    //   url: "#",
    // },
    {
      name: "Terms & Condition",
      url: "/terms-conditions",
    },
    {
      name: "Privacy Policy",
      url: "/privacy-policy",
    },
    {
      name: "Refund Policy",
      url: "/refund-policy",
    },
    {
      name: "Contact Us",
      url: "/contact-us",
    },
  ];
  return (
    <Box
      sx={{
        ...dFlex,
        backgroundColor: "var(--background-white) !important",
        top: "100%",
        position: "absolute",
        width: "100%",
      }}
      className={classes.root}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            ...flexBetween,
            margin: "0px 0px 24px 0px",
          }}
        >
          <Box>
            <Link href="/" as="/" passHref shallow>
              <Logo />
            </Link>
          </Box>
          <Box sx={flexCenter}>
            <Grid container spacing={2}>
              {footerList.map((list, index) => (
                <Grid item xs={2} sm={4} md={4} key={index}>
                  <Link href={list.url} as={list.url} passHref shallow>
                    <Typography variant="h5">{list.name}</Typography>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* <Box>
            <Box>
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: 600,
                  textTransform: "uppercase",
                  paddingBottom: "16px",
                }}
              >
                Join our community &#128293;
              </Typography>
              <Paper
                component="form"
                sx={{
                  p: "4px 8px",
                  display: "flex",
                  alignItems: "center",
                  border: "2px solid #EBECF1",
                  maxWidth: "255px",
                  borderRadius: "90px",
                  height: "48px",
                }}
              >
                <InputBase
                  sx={{
                    input: {
                      color: "#666870",
                      ml: 1,
                      flex: 1,
                      fontWeight: 500,
                      fontSize: "14px",
                    },
                  }}
                  placeholder="Enter your email"
                  inputProps={{ "aria-label": "Enter your email" }}
                />

                <BsFillArrowRightCircleFill color="#E81478" size={30} />
              </Paper>
            </Box>
          </Box> */}
        </Box>
        <Divider />
        <Typography sx={{ padding: "24px 0px" }} variant="subtitle2">
          Copyright © 2023 LLC. All rights reserved
        </Typography>
      </Container>
    </Box>
  );
}
