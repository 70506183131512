/** Flexbox styles */

export const flexBetween = {
  display: "flex",
  justifyContent: "space-between",
};

export const flexBetweenCenter = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

export const footerLayout = {
  display: "flex",
  flexDirection: { sx: "column" },
  justifyContent: { xs: "center", md: "space-between" },
  alignItems: "center",
};

export const flexColumnCenter = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  margin: "auto",
  width: "fit-content",
};
export const flexCenter = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const flexEnd = {
  display: "flex",
  justifyContent: "end",
  alignItems: "center",
};

export const fullWidthFlex = {
  display: "flex",
  width: "100%",
};

export const justifyCenter = { display: "flex", justifyContent: "center" };
export const flexAlignCenter = {
  display: "flex",
  alignItems: "center",
};

export const dFlex = {
  display: "flex",
  flexDirection: "row",
};
export const dFlexAuto = {
  marginRight: "auto",
  display: "flex",
};

export const fixedBottom = {
  position: "absolute",
  right: 8,
  bottom: 8,
  zIndex: 2,
};

export const fixedStepperBottom = {
  position: "absolute",
  bottom: "-6px",
  width: "100%",
};

export const displayOnDesktop = { display: { xs: "none", md: "block" } };

/** Custom carousel styles */

export const carouselDot = {
  color: "#fff",
  backgroundColor: "#000",
  opacity: 0.5,
  borderRadius: 10,
  p: 1,
  minWidth: "auto",
};


export const fixedIcon = {
  position: "absolute",
  right: 15,
  top: 15,
  zIndex: 2,
};
export const fixedDesktopIcon = {
  position: "absolute",
  right: 40,
  top: 40,
  zIndex: 2,
};

export const carouselImage = {
  height: 359,
  display: "block",
  overflow: "hidden",
  width: "100%",
  objectFit: "cover",
};

export const carouselImageDesk = {
  display: "block",
  overflow: "hidden",
  width: "100%",
  objectFit: "cover",
};
