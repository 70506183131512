import React from "react";
import { Box, Typography } from "@mui/material";
import { flexCenter } from "../../theme/commonThemes";
export default function Logo() {
  return (
    <Box sx={flexCenter}>
      <Typography
        sx={{
          ml: 1,
          color: (theme) => theme.palette.secondary.main,
          fontSize: "20px",
          fornWeight: "bold",
        }}
        component="h3"
      >
        <img src="/images/logo.svg" alt="" />
      </Typography>
    </Box>
  );
}
