import { Box, Container } from "@mui/material";
import Link from "next/link";
import React from "react";
import {
  dFlex,
  displayOnDesktop,
  flexBetweenCenter,
  flexCenter,
} from "../../../theme/commonThemes";
import ProfileSettings from "../HomePage/ProfileSettings";
import Logo from "../Logo";
import { useQuery } from "react-query";
import notificationModule from "../../modules/notificationModule";
import { useDispatch } from "react-redux";
import { getAuth } from "../../api/auth";

export default function Header({ children, noContainer }) {
  return (
    <Box
      sx={{
        minHeight: 88,
        backgroundColor: "var(--background-white)",
        borderBottom: "1px solid #C3C6D3",
      }}
    >
      {noContainer ? (
        <Box sx={{ padding: "24px 24px 0px 24px" }}>
          <Box
            sx={{
              ...flexBetweenCenter,
              height: 48,
            }}
          >
            <Box sx={flexCenter}>
              <Box>
                <Link href="/" as="/" passHref shallow>
                  <Logo />
                </Link>
              </Box>
            </Box>

            <Box sx={displayOnDesktop}>
              <ProfileSettings />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            ...dFlex,
          }}
        >
          <Container maxWidth="lg">
            <Box
              sx={{
                ...flexBetweenCenter,
                margin: "20px 0px",
                height: 48,
              }}
            >
              <Box sx={flexCenter}>
                <Box>
                  <Link href="/" as="/" passHref shallow>
                    <Logo />
                  </Link>
                </Box>
              </Box>

              <Box sx={displayOnDesktop}>
                <ProfileSettings />
              </Box>
            </Box>
          </Container>
        </Box>
      )}
      {children && (
        <Box
          sx={{
            p: "32px 0px",
            width: "100%",
            borderTop: "1px solid #F5F5F5",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.04)",
          }}
        >
          <Container maxWidth="lg"> {children}</Container>
        </Box>
      )}
    </Box>
  );
}
